import React, {useCallback, useEffect, useRef, useState} from 'react';
import {StyleSheet, Platform} from 'react-native';
import {
  Box,
  FormControl,
  Heading,
  Stack,
  VStack,
  Text,
  Input,
  Checkbox,
  Divider,
  Button,
  Skeleton,
  CheckIcon,
} from 'native-base';
import {Select} from '../../../Libs/CustomInputs';
import AppContainer from '../../Components/AppContainer';
import Trans from '../../Components/Trans';
import DateTimePicker from '../../../Libs/DateTimePicker';
import {NumberInput} from '../../../Libs/CustomInputs';
import {v4 as uuidv4} from 'uuid';

import main from '../../../Assets/Styles/main.json';
import FormAction from '../../../Actions/FormAction';
import APIAction from '../../../Actions/APIAction';
import GeneralAction from '../../../Actions/GeneralAction';
import TranslationAction from '../../../Actions/TranslationAction';
import UserAction from '../../../Actions/UserAction';

const mainStyle = StyleSheet.create(main);

const PersonalInfoScreen = ({navigation}) => {
  const firstLoad = useRef(true),
    scrollRef = useRef(null),
    [translations, setTranslations] = useState({
      Select: null,
    }),
    translated = useRef(false),
    [formKey, setFormKey] = useState(uuidv4()),
    [formErrors, setFormErrors] = useState([]),
    [languageOptions, setLanguageOptions] = useState([]),
    [countryOptions, setCountryOptions] = useState([]),
    [genderOptions, setGenderOptions] = useState([]),
    [maritalStatusOptions, setMaritalStatusOptions] = useState([]),
    [contractTypeOptions, setContractTypeOptions] = useState([]),
    [iceRequired, setIceRequired] = useState(false),
    completedSteps = useRef([]),
    formData = useRef({
      user: {
        id: null,
        firstName: '',
        lastName: '',
        phone: '',
        language: 'nl',
      },
      employee: {
        id: null,
        nationalRegistry: '',
        sharePhone: false,
        askAvailabilityByEmail: false,
        birthDay: new Date(),
        placeOfBirth: 'BE',
        nationality: 'BE',
        gender: null,
        maritalStatus: null,
        contractType: null,
        dependents: 0,
        handicapped: false,
      },
      address: {
        id: null,
        street: '',
        streetNumber: '',
        suite: '',
        zip: '',
        place: '',
        country: 'BE',
      },
      roomAddress: {
        id: null,
        street: '',
        streetNumber: '',
        suite: '',
        zip: '',
        place: '',
        country: 'BE',
      },
      iceContact: {
        id: null,
        firstName: '',
        lastName: '',
        phone: '',
        description: '',
      },
    }),
    [dataFetched, setDataFetched] = useState(false),
    [waiting, setWaiting] = useState(false);
  /**
   * Set translations once
   */
  useEffect(() => {
    const translate = async () => {
      let newTranslations = await TranslationAction.translateInLine(
        Object.keys(translations),
      );
      setTranslations(newTranslations);
    };
    if (!translated.current) {
      translated.current = true;
      translate();
    }
  }, [translations]);

  /**
   * Fetch data to fill form
   */
  const fetchData = useCallback(() => {
    const init = async () => {
      //get languages
      let languages = await APIAction.request({
        method: 'GET',
        url: '/api/languages',
        cache: true,
      });
      languages = languages['hydra:member'];
      //set language options
      let newLanguageOptions = [];
      for (let language of languages) {
        let langLabel = await GeneralAction.getLanguage(language.code);
        newLanguageOptions.push(
          <Select.Item value={language['@id']} label={langLabel} />,
        );
      }
      setLanguageOptions(newLanguageOptions);

      //set countries
      let countries = await GeneralAction.getCountries();
      let newCountryOptions = [];
      for (let [countryCode, countryName] of Object.entries(countries)) {
        newCountryOptions.push(
          <Select.Item value={countryCode} label={countryName} />,
        );
      }
      setCountryOptions(newCountryOptions);

      //set genders
      let genders = await GeneralAction.getGenders();
      let newGenderOptions = [];
      for (let [genderCode, genderName] of Object.entries(genders)) {
        newGenderOptions.push(
          <Select.Item value={genderCode} label={genderName} />,
        );
      }
      setGenderOptions(newGenderOptions);

      //get marital satuses
      let maritalStatuses = await APIAction.request({
        method: 'GET',
        url: '/api/easy_pay_marital_statuses',
        params: {
          forProject: false,
        },
        cache: true,
      });
      maritalStatuses = maritalStatuses['hydra:member'];
      console.log('maritalStatuses', maritalStatuses);
      //set martial status options
      let newMaritalStatusOptions = [];
      for (let maritalStatus of maritalStatuses) {
        let maritalStatusLabel = await TranslationAction.translateArray(
          maritalStatus.name,
        );
        newMaritalStatusOptions.push(
          <Select.Item
            value={maritalStatus['@id']}
            label={maritalStatusLabel}
          />,
        );
      }
      setMaritalStatusOptions(newMaritalStatusOptions);

      //get contract types
      let contractTypes = await APIAction.request({
        method: 'GET',
        url: '/api/contract_types',
        cache: true,
      });
      contractTypes = contractTypes['hydra:member'];

      //set contract type options
      let newContractTypeOptions = [];
      for (let contractType of contractTypes) {
        let contractTypeLabel = await TranslationAction.translateArray(
          contractType.name,
        );
        newContractTypeOptions.push(
          <Select.Item value={contractType['@id']} label={contractTypeLabel} />,
        );
      }
      setContractTypeOptions(newContractTypeOptions);

      //get current user
      let currentUser = await APIAction.request({
        method: 'GET',
        url: `/api/current/user`,
      });
      await UserAction.setUser(currentUser);

      //set completed steps
      completedSteps.current = currentUser.employee.completedSteps || [];

      //set values
      formData.current.user.id = currentUser.id;
      formData.current.user.firstName = currentUser.firstName;
      formData.current.user.lastName = currentUser.lastName;
      formData.current.user.phone = currentUser.phone;
      formData.current.user.language = currentUser.language?.['@id'] || null;

      formData.current.employee.id = currentUser.employee.id;
      formData.current.employee.nationalRegistry =
        currentUser.employee.nationalRegistry;
      formData.current.employee.sharePhone = currentUser.employee.sharePhone;
      formData.current.employee.askAvailabilityByEmail =
        currentUser.employee.askAvailabilityByEmail;
      formData.current.employee.birthDay = currentUser.employee.birthDay
        ? new Date(currentUser.employee.birthDay)
        : null;
      formData.current.employee.placeOfBirth =
        currentUser.employee.placeOfBirth || 'BE';
      formData.current.employee.nationality =
        currentUser.employee.nationality || 'BE';
      formData.current.employee.gender = currentUser.employee.gender;
      formData.current.employee.maritalStatus =
        currentUser.employee.maritalStatus?.['@id'] || null;
      formData.current.employee.contractType =
        currentUser.employee.contractType?.['@id'] || null;
      formData.current.employee.dependents =
        currentUser.employee.dependents || 0;
      formData.current.employee.handicapped = currentUser.employee.handicapped;

      if (currentUser.employee.address) {
        formData.current.address.id = currentUser.employee.address?.id || null;
        formData.current.address.suite =
          currentUser.employee.address.suite ?? '';
        formData.current.address.street = currentUser.employee.address.street;
        formData.current.address.streetNumber =
          currentUser.employee.address.streetNumber;
        formData.current.address.zip = currentUser.employee.address.zip;
        formData.current.address.place = currentUser.employee.address.place;
        formData.current.address.country = currentUser.employee.address.country;
      }

      if (currentUser.employee.roomAddress) {
        formData.current.roomAddress.id =
          currentUser.employee.roomAddress?.id || null;
        formData.current.address.suite =
          currentUser.employee.address.suite ?? '';
        formData.current.roomAddress.street =
          currentUser.employee.roomAddress.street;
        formData.current.roomAddress.streetNumber =
          currentUser.employee.roomAddress.streetNumber;
        formData.current.roomAddress.zip = currentUser.employee.roomAddress.zip;
        formData.current.roomAddress.place =
          currentUser.employee.roomAddress.place;
        formData.current.roomAddress.country =
          currentUser.employee.roomAddress.country;
      }

      if (currentUser.employee.iceContact) {
        formData.current.iceContact.id =
          currentUser.employee.iceContact?.id || null;
        formData.current.iceContact.firstName =
          currentUser.employee.iceContact.firstName;
        formData.current.iceContact.lastName =
          currentUser.employee.iceContact.lastName;
        formData.current.iceContact.phone =
          currentUser.employee.iceContact.phone;
        formData.current.iceContact.description =
          currentUser.employee.iceContact.description;
      }

      //reset key after load
      setFormKey(uuidv4());
      setDataFetched(true);

      //set ice required
      if (currentUser.employee.forProject) {
        setIceRequired(true);
      }
    };
    init();
  }, []);

  /**
   * Trigger on first load
   */
  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      fetchData();
    }
  }, [fetchData]);

  /**
   * Easily validate fields
   *
   * @param {string} field Name of field with underscore between segments
   * @param {string} type Name of validator to use as available in FormAction
   * @param {array} errors Array that fields with errors will be appended to
   */
  const validateField = (field, type, errors) => {
    let splitField = field.split('_');
    let result = FormAction[type](
      formData.current[splitField[0]][splitField[1]],
    );
    if (!result) errors.push(field);
  };

  /**
   * Submit form
   */
  const submit = async () => {
    // Name of step
    let nameStep = 'personalInfo';

    // Clear errors
    setFormErrors([]);

    // New error array
    let errors = [];

    // Validate
    validateField('user_firstName', 'validateEmpty', errors);
    validateField('user_lastName', 'validateEmpty', errors);
    validateField('user_phone', 'validateEmpty', errors);
    validateField('user_phone', 'validatePhone', errors);
    validateField('employee_nationalRegistry', 'validateEmpty', errors);
    validateField('employee_nationalRegistry', 'vaildateRRN', errors);
    validateField('user_language', 'validateEmpty', errors);
    validateField('employee_birthDay', 'validateEmpty', errors);
    validateField('employee_placeOfBirth', 'validateEmpty', errors);
    validateField('employee_nationality', 'validateEmpty', errors);
    validateField('employee_gender', 'validateEmpty', errors);
    validateField('employee_maritalStatus', 'validateEmpty', errors);
    validateField('employee_contractType', 'validateEmpty', errors);
    validateField('employee_dependents', 'validateNumber', errors);
    validateField('address_street', 'validateEmpty', errors);
    validateField('address_streetNumber', 'validateEmpty', errors);
    validateField('address_zip', 'validateEmpty', errors);
    validateField('address_place', 'validateEmpty', errors);
    validateField('address_country', 'validateEmpty', errors);
    /*validateField('roomaddress_street', 'validateEmpty', errors);
        validateField('roomaddress_streetNumber', 'validateEmpty', errors);
        validateField('roomaddress_zip', 'validateEmpty', errors);
        validateField('roomaddress_place', 'validateEmpty', errors);
        validateField('roomaddress_country', 'validateEmpty', errors);*/
    if (iceRequired) {
      validateField('iceContact_firstName', 'validateEmpty', errors);
      validateField('iceContact_lastName', 'validateEmpty', errors);
      validateField('iceContact_phone', 'validateEmpty', errors);
      validateField('iceContact_phone', 'validatePhone', errors);
      validateField('iceContact_description', 'validateEmpty', errors);
    }

    //set errors
    setFormErrors(errors);

    //if no errors, continue
    if (errors.length === 0) {
      //send user changes
      await APIAction.request({
        method: 'PATCH',
        url: `/api/users/${formData.current.user.id}`,
        body: {
          firstName: formData.current.user.firstName,
          lastName: formData.current.user.lastName,
          phone: formData.current.user.phone,
          language: formData.current.user.language,
        },
      });

      //send address changes
      let newAddress = formData.current.address.id === null;
      let address = await APIAction.request({
        method: newAddress ? 'POST' : 'PATCH',
        url: `/api/addresses${
          newAddress ? '' : `/${formData.current.address.id}`
        }`,
        body: {
          street: formData.current.address.street,
          streetNumber: formData.current.address.streetNumber,
          suite: formData.current.address.suite,
          zip: formData.current.address.zip,
          place: formData.current.address.place,
          country: formData.current.address.country,
        },
      });

      let roomAddress = null;
      if (
        formData.current.roomAddress.street &&
        formData.current.roomAddress.streetNumber &&
        formData.current.roomAddress.zip &&
        formData.current.roomAddress.place &&
        formData.current.roomAddress.country
      ) {
        //send room address changes
        let newRoomAddress = formData.current.roomAddress.id === null;
        roomAddress = await APIAction.request({
          method: newRoomAddress ? 'POST' : 'PATCH',
          url: `/api/addresses${
            newRoomAddress ? '' : `/${formData.current.roomAddress.id}`
          }`,
          body: {
            street: formData.current.roomAddress.street,
            streetNumber: formData.current.roomAddress.streetNumber,
            zip: formData.current.roomAddress.zip,
            suite: formData.current.roomAddress.suite,
            place: formData.current.roomAddress.place,
            country: formData.current.roomAddress.country,
          },
        });
      }

      let iceContact = null;
      if (
        formData.current.iceContact.firstName &&
        formData.current.iceContact.lastName &&
        formData.current.iceContact.phone &&
        formData.current.iceContact.description
      ) {
        //send ice contact changes
        let newIceContact = formData.current.iceContact.id === null;
        iceContact = await APIAction.request({
          method: newIceContact ? 'POST' : 'PATCH',
          url: `/api/ice_contacts${
            newIceContact ? '' : `/${formData.current.iceContact.id}`
          }`,
          body: {
            firstName: formData.current.iceContact.firstName,
            lastName: formData.current.iceContact.lastName,
            phone: formData.current.iceContact.phone,
            description: formData.current.iceContact.description,
          },
        });
      }

      //check commpleted steps
      if (!completedSteps.current.includes(nameStep)) {
        completedSteps.current.push(nameStep);
      }

      //send employee changes
      let employeeBody = {
        completedSteps: completedSteps.current,
        nationalRegistry: formData.current.employee.nationalRegistry,
        sharePhone: formData.current.employee.sharePhone,
        askAvailabilityByEmail:
          formData.current.employee.askAvailabilityByEmail,
        birthDay: new Date(formData.current.employee.birthDay),
        placeOfBirth: formData.current.employee.placeOfBirth,
        nationality: formData.current.employee.nationality,
        gender: formData.current.employee.gender,
        maritalStatus: formData.current.employee.maritalStatus,
        contractType: formData.current.employee.contractType,
        dependents: formData.current.employee.dependents,
        address: address['@id'],
      };

      if (iceContact) {
        employeeBody['iceContact'] = iceContact['@id'];
      }

      if (roomAddress) {
        employeeBody['roomAddress'] = roomAddress['@id'];
      }

      await APIAction.request({
        method: 'PATCH',
        url: `/api/employees/${formData.current.employee.id}`,
        body: employeeBody,
      });

      // Send message
      // GeneralAction.toast('success', <Trans>Information saved</Trans>);
      setTimeout(() => {
        setWaiting(false);
        navigation.replace('CompleteProfile', {id: formData.current.user.id});
      }, 2000);
    } else {
      setWaiting(false);
      GeneralAction.toast('error', <Trans>Some fields are invalid</Trans>);
      scrollRef.current.scrollTo({x: 0, y: 0, animated: true});
    }
  };

  let iceProps = {};
  if (iceRequired) {
    iceProps = {isRequired: true};
  }

  return (
    <AppContainer noTabBar scrollRef={scrollRef}>
      {dataFetched ? (
        <VStack space={2}>
          <Box style={mainStyle.pageTitleWrapper}>
            <Box flexGrow={1} flexShrink={1}>
              <Heading style={mainStyle.pageTitle}>
                <Trans>Personal info</Trans>
              </Heading>
            </Box>
          </Box>
          <Stack
            space={2}
            direction={{
              base: 'column',
              md: 'row',
            }}>
            <Box flex={1}>
              <FormControl
                isRequired
                isInvalid={formErrors.includes('user_firstName')}>
                <FormControl.Label>
                  <Text>
                    <Trans>First name</Trans>
                  </Text>
                </FormControl.Label>
                <Input
                  key={`${formKey}_firstName`}
                  defaultValue={formData.current.user.firstName}
                  onChangeText={val => {
                    formData.current.user.firstName = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is required</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
            <Box flex={1}>
              <FormControl
                isRequired
                isInvalid={formErrors.includes('user_lastName')}>
                <FormControl.Label>
                  <Text>
                    <Trans>Last name</Trans>
                  </Text>
                </FormControl.Label>
                <Input
                  key={`${formKey}_lastName`}
                  defaultValue={formData.current.user.lastName}
                  onChangeText={val => {
                    formData.current.user.lastName = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is required</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
          </Stack>
          <Stack
            space={2}
            direction={{
              base: 'column',
              md: 'row',
            }}>
            <Box flex={1}>
              <FormControl
                isRequired
                isInvalid={formErrors.includes('user_phone')}>
                <FormControl.Label>
                  <Text>
                    <Trans>Mobile</Trans>
                  </Text>
                </FormControl.Label>
                <Input
                  key={`${formKey}_phone`}
                  defaultValue={formData.current.user.phone}
                  onChangeText={val => {
                    formData.current.user.phone = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is not valid</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
            <Box flex={1}>
              <FormControl
                isRequired
                isInvalid={formErrors.includes('employee_nationalRegistry')}>
                <FormControl.Label>
                  <Text>
                    <Trans>National registry</Trans>
                  </Text>
                </FormControl.Label>
                <Input
                  key={`${formKey}_nationalRegistry`}
                  defaultValue={formData.current.employee.nationalRegistry}
                  onChangeText={val => {
                    formData.current.employee.nationalRegistry = val.replace(
                      /\D/g,
                      '',
                    );
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is required</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
            <Box flex={1}>
              <Box>
                <FormControl.Label isRequired>
                  <Text>
                    <Trans>Language</Trans>
                  </Text>
                </FormControl.Label>
                <Select
                  isInvalid={formErrors.includes('user_language')}
                  key={`${formKey}_language`}
                  placeholder={translations['Select']}
                  _selectedItem={{endIcon: <CheckIcon size={5} />}}
                  defaultValue={formData.current.user.language}
                  onValueChange={val => {
                    formData.current.user.language = val;
                  }}>
                  {languageOptions}
                </Select>
              </Box>
            </Box>
          </Stack>
          <Stack
            space={2}
            direction={{
              base: 'column',
              md: 'row',
            }}>
            <Box flex={1}>
              <Checkbox
                key={`${formKey}_sharePhone`}
                isInvalid={formErrors.includes('employee_sharePhone')}
                defaultIsChecked={formData.current.employee.sharePhone}
                onChange={val => {
                  formData.current.employee.sharePhone = val;
                }}>
                <Text>
                  <Trans>Share phone number with other employees?</Trans>
                </Text>
              </Checkbox>
            </Box>
            <Box flex={1}>
              <Checkbox
                key={`${formKey}_askAvailabilityByEmail`}
                isInvalid={formErrors.includes(
                  'employee_askAvailabilityByEmail',
                )}
                defaultIsChecked={
                  formData.current.employee.askAvailabilityByEmail
                }
                onChange={val => {
                  formData.current.employee.askAvailabilityByEmail = val;
                }}>
                <Text>
                  <Trans>Ask availability by email?</Trans>
                </Text>
              </Checkbox>
            </Box>
          </Stack>
          <Stack
            space={2}
            direction={{
              base: 'column',
              md: 'row',
            }}>
            <Box flex={1}>
              <FormControl
                isRequired
                isInvalid={formErrors.includes('employee_birthDay')}>
                <FormControl.Label>
                  <Text>
                    <Trans>Birthday</Trans>
                  </Text>
                </FormControl.Label>
                <DateTimePicker
                  key={`${formKey}_birthDay`}
                  mode={'date'}
                  value={formData.current.employee.birthDay}
                  onChange={val => {
                    formData.current.employee.birthDay = new Date(val);

                    if (Platform.OS !== 'web') {
                      setFormKey(uuidv4());
                    }
                  }}
                  onAccept={() => {
                    setFormKey(uuidv4());
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is required</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
            <Box flex={1}>
              <Box>
                <FormControl.Label isRequired>
                  <Text>
                    <Trans>Place of birth</Trans>
                  </Text>
                </FormControl.Label>
                <Select
                  isInvalid={formErrors.includes('employee_placeOfBirth')}
                  key={`${formKey}_placeOfBirth`}
                  placeholder={translations['Select']}
                  _selectedItem={{endIcon: <CheckIcon size={5} />}}
                  defaultValue={formData.current.employee.placeOfBirth}
                  onValueChange={val => {
                    formData.current.employee.placeOfBirth = val;
                  }}>
                  {countryOptions}
                </Select>
              </Box>
            </Box>
            <Box flex={1}>
              <Box>
                <FormControl.Label isRequired>
                  <Text>
                    <Trans>Nationality</Trans>
                  </Text>
                </FormControl.Label>
                <Select
                  isInvalid={formErrors.includes('employee_placeOfBirth')}
                  key={`${formKey}_nationality`}
                  placeholder={translations['Select']}
                  _selectedItem={{endIcon: <CheckIcon size={5} />}}
                  defaultValue={formData.current.employee.nationality}
                  onValueChange={val => {
                    formData.current.employee.nationality = val;
                  }}>
                  {countryOptions}
                </Select>
              </Box>
            </Box>
          </Stack>
          <Stack
            space={2}
            direction={{
              base: 'column',
              md: 'row',
            }}>
            <Box flex={1}>
              <Box>
                <FormControl.Label isRequired>
                  <Text>
                    <Trans>Gender</Trans>
                  </Text>
                </FormControl.Label>
                <Select
                  isInvalid={formErrors.includes('employee_gender')}
                  key={`${formKey}_gender`}
                  placeholder={translations['Select']}
                  _selectedItem={{endIcon: <CheckIcon size={5} />}}
                  defaultValue={formData.current.employee.gender}
                  onValueChange={val => {
                    formData.current.employee.gender = val;
                  }}>
                  {genderOptions}
                </Select>
              </Box>
            </Box>
            <Box flex={1}>
              <Box>
                <FormControl.Label isRequired>
                  <Text>
                    <Trans>Marital status</Trans>
                  </Text>
                </FormControl.Label>
                <Select
                  isInvalid={formErrors.includes('employee_maritalStatus')}
                  key={`${formKey}_maritalStatus`}
                  placeholder={translations['Select']}
                  _selectedItem={{endIcon: <CheckIcon size={5} />}}
                  defaultValue={formData.current.employee.maritalStatus}
                  onValueChange={val => {
                    formData.current.employee.maritalStatus = val;
                  }}>
                  {maritalStatusOptions}
                </Select>
              </Box>
            </Box>
          </Stack>
          <Stack
            space={2}
            direction={{
              base: 'column',
              md: 'row',
            }}>
            <Box flex={1}>
              <Box>
                <FormControl.Label isRequired>
                  <Text>
                    <Trans>Contract type</Trans>
                  </Text>
                </FormControl.Label>
                <Select
                  isInvalid={formErrors.includes('employee_contractType')}
                  key={`${formKey}_contractType`}
                  placeholder={translations['Select']}
                  _selectedItem={{endIcon: <CheckIcon size={5} />}}
                  defaultValue={formData.current.employee.contractType}
                  onValueChange={val => {
                    formData.current.employee.contractType = val;
                  }}>
                  {contractTypeOptions}
                </Select>
              </Box>
            </Box>
            <Box flex={1}>
              <FormControl
                isRequired
                isInvalid={formErrors.includes('employee_dependents')}>
                <FormControl.Label>
                  <Text>
                    <Trans>Dependents</Trans>
                  </Text>
                </FormControl.Label>
                <NumberInput
                  key={`${formKey}_dependents`}
                  min={0}
                  value={formData.current.employee.dependents}
                  onChange={val => {
                    formData.current.employee.dependents = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is not valid</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
          </Stack>
          <Box>
            <Checkbox
              key={`${formKey}_handicapped`}
              isInvalid={formErrors.includes('employee_handicapped')}
              defaultIsChecked={formData.current.employee.handicapped}
              onChange={val => {
                formData.current.employee.handicapped = val;
              }}>
              <Text>
                <Trans>Do you have a disability?</Trans>
              </Text>
            </Checkbox>
          </Box>
          <Divider my={2} />
          <Box style={mainStyle.pageTitleWrapper}>
            <Box flexGrow={1} flexShrink={1}>
              <Heading style={mainStyle.pageTitle}>
                <Trans>Address</Trans>
              </Heading>
            </Box>
          </Box>
          <Stack
            space={2}
            direction={{
              base: 'column',
              sm: 'row',
            }}>
            <Box flex={4}>
              <FormControl
                isRequired
                isInvalid={formErrors.includes('address_street')}>
                <FormControl.Label>
                  <Text>
                    <Trans>Street</Trans>
                  </Text>
                </FormControl.Label>
                <Input
                  key={`${formKey}_address_street`}
                  defaultValue={formData.current.address.street}
                  onChangeText={val => {
                    formData.current.address.street = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is required</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
            <Box flex={1}>
              <FormControl
                isRequired
                isInvalid={formErrors.includes('address_streetNumber')}>
                <FormControl.Label>
                  <Text>
                    <Trans>Number</Trans>
                  </Text>
                </FormControl.Label>
                <Input
                  key={`${formKey}_address_streetNumber`}
                  defaultValue={formData.current.address.streetNumber}
                  onChangeText={val => {
                    formData.current.address.streetNumber = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is required</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
            <Box flex={1}>
              <FormControl>
                <FormControl.Label>
                  <Text>
                    <Trans>Suite</Trans>
                  </Text>
                </FormControl.Label>
                <Input
                  key={`${formKey}_address_suite`}
                  defaultValue={formData.current.address.suite}
                  onChangeText={val => {
                    formData.current.address.suite = val;
                  }}
                />
              </FormControl>
            </Box>
          </Stack>
          <Stack
            space={2}
            direction={{
              base: 'column',
              sm: 'row',
            }}>
            <Box flex={1}>
              <FormControl
                isRequired
                isInvalid={formErrors.includes('address_zip')}>
                <FormControl.Label>
                  <Text>
                    <Trans>Zip</Trans>
                  </Text>
                </FormControl.Label>
                <Input
                  key={`${formKey}_address_zip`}
                  defaultValue={formData.current.address.zip}
                  onChangeText={val => {
                    formData.current.address.zip = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is required</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
            <Box flex={1}>
              <FormControl
                isRequired
                isInvalid={formErrors.includes('address_place')}>
                <FormControl.Label>
                  <Text>
                    <Trans>City</Trans>
                  </Text>
                </FormControl.Label>
                <Input
                  key={`${formKey}_address_place`}
                  defaultValue={formData.current.address.place}
                  onChangeText={val => {
                    formData.current.address.place = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is required</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
          </Stack>
          <Box>
            <Box>
              <FormControl.Label isRequired>
                <Text>
                  <Trans>Country</Trans>
                </Text>
              </FormControl.Label>
              <Select
                isInvalid={formErrors.includes('address_country')}
                key={`${formKey}_address_country`}
                placeholder={translations['Select']}
                _selectedItem={{endIcon: <CheckIcon size={5} />}}
                defaultValue={formData.current.address.country}
                onValueChange={val => {
                  formData.current.address.country = val;
                }}>
                {countryOptions}
              </Select>
            </Box>
          </Box>
          <Divider my={2} />
          <Box style={mainStyle.pageTitleWrapper}>
            <Box flexGrow={1} flexShrink={1}>
              <Heading style={mainStyle.pageTitle}>
                <Trans>Room address</Trans>
              </Heading>
            </Box>
          </Box>
          <Stack
            space={2}
            direction={{
              base: 'column',
              sm: 'row',
            }}>
            <Box flex={4}>
              <FormControl
                isInvalid={formErrors.includes('roomaddress_street')}>
                <FormControl.Label>
                  <Text>
                    <Trans>Street</Trans>
                  </Text>
                </FormControl.Label>
                <Input
                  key={`${formKey}_roomaddress_street`}
                  defaultValue={formData.current.roomAddress.street}
                  onChangeText={val => {
                    formData.current.roomAddress.street = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is required</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
            <Box flex={1}>
              <FormControl
                isInvalid={formErrors.includes('roomaddress_streetNumber')}>
                <FormControl.Label>
                  <Text>
                    <Trans>Number</Trans>
                  </Text>
                </FormControl.Label>
                <Input
                  key={`${formKey}_roomaddress_streetNumber`}
                  defaultValue={formData.current.roomAddress.streetNumber}
                  onChangeText={val => {
                    formData.current.roomAddress.streetNumber = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is required</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
          </Stack>
          <Stack
            space={2}
            direction={{
              base: 'column',
              sm: 'row',
            }}>
            <Box flex={1}>
              <FormControl isInvalid={formErrors.includes('roomaddress_zip')}>
                <FormControl.Label>
                  <Text>
                    <Trans>Zip</Trans>
                  </Text>
                </FormControl.Label>
                <Input
                  key={`${formKey}_roomaddress_zip`}
                  defaultValue={formData.current.roomAddress.zip}
                  onChangeText={val => {
                    formData.current.roomAddress.zip = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is required</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
            <Box flex={1}>
              <FormControl isInvalid={formErrors.includes('roomaddress_place')}>
                <FormControl.Label>
                  <Text>
                    <Trans>City</Trans>
                  </Text>
                </FormControl.Label>
                <Input
                  key={`${formKey}_roomaddress_place`}
                  defaultValue={formData.current.roomAddress.place}
                  onChangeText={val => {
                    formData.current.roomAddress.place = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is required</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
          </Stack>
          <Box>
            <Box>
              <FormControl.Label>
                <Text>
                  <Trans>Country</Trans>
                </Text>
              </FormControl.Label>
              <Select
                isInvalid={formErrors.includes('roomaddress_country')}
                key={`${formKey}_roomaddress_country`}
                placeholder={translations['Select']}
                _selectedItem={{endIcon: <CheckIcon size={5} />}}
                defaultValue={formData.current.roomAddress.country}
                onValueChange={val => {
                  formData.current.roomAddress.country = val;
                }}>
                {countryOptions}
              </Select>
            </Box>
          </Box>
          <Divider my={2} />
          <Box style={mainStyle.pageTitleWrapper}>
            <Box flexGrow={1} flexShrink={1}>
              <Heading style={mainStyle.pageTitle}>
                <Trans>Ice contact</Trans>
              </Heading>
            </Box>
          </Box>
          <Stack
            space={2}
            direction={{
              base: 'column',
              md: 'row',
            }}>
            <Box flex={1}>
              <FormControl
                {...iceProps}
                isInvalid={formErrors.includes('iceContact_firstName')}>
                <FormControl.Label>
                  <Text>
                    <Trans>First name</Trans>
                  </Text>
                </FormControl.Label>
                <Input
                  key={`${formKey}_ice_firstName`}
                  defaultValue={formData.current.iceContact.firstName}
                  onChangeText={val => {
                    formData.current.iceContact.firstName = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is required</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
            <Box flex={1}>
              <FormControl
                {...iceProps}
                isInvalid={formErrors.includes('iceContact_lastName')}>
                <FormControl.Label>
                  <Text>
                    <Trans>Last name</Trans>
                  </Text>
                </FormControl.Label>
                <Input
                  key={`${formKey}_ice_lastName`}
                  defaultValue={formData.current.iceContact.lastName}
                  onChangeText={val => {
                    formData.current.iceContact.lastName = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is required</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
          </Stack>
          <Stack
            space={2}
            direction={{
              base: 'column',
              md: 'row',
            }}>
            <Box flex={1}>
              <FormControl
                {...iceProps}
                isInvalid={formErrors.includes('iceContact_phone')}>
                <FormControl.Label>
                  <Text>
                    <Trans>Phone</Trans>
                  </Text>
                </FormControl.Label>
                <Input
                  key={`${formKey}_ice_phone`}
                  defaultValue={formData.current.iceContact.phone}
                  onChangeText={val => {
                    formData.current.iceContact.phone = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is not valid</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
            <Box flex={1}>
              <FormControl
                {...iceProps}
                isInvalid={formErrors.includes('iceContact_description')}>
                <FormControl.Label>
                  <Text>
                    <Trans>Description</Trans>
                  </Text>
                </FormControl.Label>
                <Input
                  key={`${formKey}_ice_description`}
                  defaultValue={formData.current.iceContact.description}
                  onChangeText={val => {
                    formData.current.iceContact.description = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is required</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
          </Stack>
          <Divider my={2} />
          <Box>
            <Button
              colorScheme={'success'}
              isLoading={waiting}
              disabled={waiting}
              onPress={() => {
                setWaiting(true);
                submit();
              }}>
              <Text color={'#fff'}>
                <Trans>Done</Trans>
              </Text>
            </Button>
          </Box>
        </VStack>
      ) : (
        <VStack space={2}>
          <Skeleton.Text lines={1} w={'50px'} />
          <Box>
            <Skeleton.Text lines={1} mb={1} w={'50%'} />
            <Skeleton />
          </Box>
          <Box>
            <Skeleton.Text lines={1} mb={1} w={'50%'} />
            <Skeleton />
          </Box>
          <Divider my={2} />
          <Skeleton.Text lines={1} w={'50px'} />
          <Box>
            <Skeleton.Text lines={1} mb={1} w={'50%'} />
            <Skeleton />
          </Box>
          <Box>
            <Skeleton.Text lines={1} mb={1} w={'50%'} />
            <Skeleton />
          </Box>
          <Divider my={2} />
          <Skeleton.Text lines={1} w={'50px'} />
          <Box>
            <Skeleton.Text lines={1} mb={1} w={'50%'} />
            <Skeleton />
          </Box>
          <Box>
            <Skeleton.Text lines={1} mb={1} w={'50%'} />
            <Skeleton />
          </Box>
          <Divider my={2} />
          <Skeleton />
        </VStack>
      )}
    </AppContainer>
  );
};

export default PersonalInfoScreen;
