import React, {useEffect, useState, useRef, useCallback} from 'react';
import {StyleSheet, Linking} from 'react-native';
import {Box, Heading, Text} from 'native-base';
import main from '../../Assets/Styles/main.json';
import AppContainer from '../Components/AppContainer';
import Trans from '../Components/Trans';
import CheckListItem from './Components/CheckListItem';
import GeneralAction from '../../Actions/GeneralAction';
import {useIsFocused} from '@react-navigation/native';
import APIAction from '../../Actions/APIAction';
import {v4 as uuidv4} from 'uuid';
import UserAction from '../../Actions/UserAction';
import Config from '../../Libs/Config';
import TranslationAction from '../../Actions/TranslationAction';

const mainStyle = StyleSheet.create(main);

const CompleteProfileScreen = ({navigation}) => {
  //state
  let firstLoad = useRef(true);
  let refreshInterval = useRef(null);
  let [refreshId, setRefreshId] = useState(uuidv4());
  let [user, setUser] = useState(null);
  let [hasIdentityVerifications, setHasIdentityVerifications] = useState(false);
  let [hasPaymentVerifications, setHasPaymentVerifications] = useState(false);
  let [personalDetails, setPersonalDetails] = useState(false);
  let [hasAdditionalInfo, setAdditionalInfo] = useState(false);
  let [approvalRequested, setApprovalRequested] = useState(false);
  let [requestSend, setRequestSend] = useState(false);
  let [hasDocumentsToSign, setHasDocumentsToSign] = useState(true);
  let [verified, setVerified] = useState(false);
  let [languageCode, setLanguageCode] = useState(null);

  const isFocused = useIsFocused();

  const checkDocuments = useCallback(
    employee => {
      const init = async () => {
        refreshId;
        // Check if user must sign documents
        let signDocuments = await APIAction.request({
          method: 'GET',
          url: '/api/check/contracts',
          params: {
            employeeId: employee.id,
            sendEmail: true,
          },
        });

        setHasDocumentsToSign(signDocuments);

        return refreshId;
      };
      init();
    },
    [refreshId],
  );

  const getData = useCallback(() => {
    const init = async () => {
      if (isFocused) {
        let dbUser = await APIAction.request({
          method: 'GET',
          url: '/api/current/user',
        });

        let hasEmployeeDetails = true;
        let additionalInfo = false;

        setUser(dbUser);
        if (dbUser.employee) {
          if (!requestSend) {
            setApprovalRequested(dbUser.employee.approvalRequested);
          }

          let identityVerification = await APIAction.request({
            method: 'GET',
            url: '/api/identity_verifications/get/open',
          });

          if (identityVerification) {
            setHasIdentityVerifications(true);
          }

          let employee = dbUser.employee;
          let paymentVerification = await APIAction.request({
            method: 'GET',
            url: '/api/payment_verifications/get/open',
          });

          if (paymentVerification) {
            let hasCardDetails = employee.iban && employee.bic;
            setHasPaymentVerifications(hasCardDetails);
            setRefreshId(uuidv4());
            checkDocuments(employee);
          }

          // Check if we have all employee details
          if (
            !employee.gender ||
            !employee.address ||
            !employee.placeOfBirth ||
            !employee.nationality ||
            !employee.birthDay ||
            !employee.maritalStatus ||
            !employee.contractType ||
            !employee.nationalRegistry
          ) {
            hasEmployeeDetails = false;
          }

          if (
            employee.bodyLength ||
            employee.sizeShoe ||
            employee.sizeBottom ||
            employee.sizeTop ||
            employee.car ||
            employee.driversLicense ||
            employee.nbrSeatsInCar
          ) {
            additionalInfo = true;
          }

          setVerified(employee.paymentVerified && employee.identityVerified);
        }

        setAdditionalInfo(additionalInfo);

        // Check if we have all personal details
        if (dbUser) {
          let hasPersonalDetails =
            dbUser.firstName && dbUser.lastName && dbUser.phone;
          setPersonalDetails(hasEmployeeDetails && hasPersonalDetails);
          if (hasEmployeeDetails && hasPersonalDetails) {
            // Make sure that documents are generated for employees
            let employeeUrl = '/api/employees/' + dbUser.employee.id;
            await APIAction.request({
              method: 'PATCH',
              url: employeeUrl,
              body: {
                generateDocuments: true,
              },
            });
          }
        }
      }
    };
    init();
  }, [isFocused, requestSend, checkDocuments]);

  //on load
  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      getData();

      const getLanguage = async () => {
        let language = await TranslationAction.getSelectedLanguage();
        setLanguageCode(language.code);
      };
      getLanguage();
    }
  }, [getData]);

  let requestApproval = async () => {
    // A user shouldn't be able to request approval
    setRequestSend(true);
    setApprovalRequested(true);

    await APIAction.request({
      method: 'PATCH',
      url: '/api/employees/request/approval',
      body: {},
    });

    GeneralAction.toast('success', <Trans>Approval request sent!</Trans>);
  };

  useEffect(() => {
    if (isFocused) {
      if (refreshInterval.current === null && user) {
        refreshInterval.current = setInterval(() => {
          setRefreshId(uuidv4());
          checkDocuments(user.employee);
        }, 30000);
      }
    } else {
      clearInterval(refreshInterval.current);
      refreshInterval.current = null;
      firstLoad.current = true;
    }
  }, [isFocused, checkDocuments, user]);

  return (
    <AppContainer noTabBar>
      {user && user.employee && !user.employee.reasonDenied && (
        <>
          <Box style={mainStyle.pageTitleWrapper}>
            <Box style={{flexGrow: 1}}>
              <Heading style={mainStyle.pageTitle}>
                <Trans>Complete your profile</Trans>
              </Heading>
            </Box>
          </Box>

          <Box style={mainStyle.boxItem}>
            <Box>
              <CheckListItem
                first
                number={1}
                active={true}
                ready={personalDetails}
                title={<Trans>Personal info</Trans>}
                onPress={() => {
                  navigation.navigate('CompletePersonalInfo');
                }}
              />
              <CheckListItem
                number={2}
                active={personalDetails}
                ready={hasIdentityVerifications}
                title={<Trans>ID card</Trans>}
                onPress={
                  personalDetails &&
                  (() => {
                    navigation.navigate('CompleteProfileID');
                  })
                }
              />
              <CheckListItem
                number={3}
                active={hasIdentityVerifications}
                ready={hasPaymentVerifications}
                title={<Trans>Bank details</Trans>}
                onPress={
                  hasIdentityVerifications &&
                  (() => {
                    navigation.navigate('CompleteBankDetails');
                  })
                }
              />
              <CheckListItem
                number={4}
                active={hasPaymentVerifications}
                ready={hasAdditionalInfo}
                title={<Trans>Additional info</Trans>}
                onPress={
                  hasPaymentVerifications &&
                  (() => {
                    navigation.navigate('CompleteAdditionalInfo');
                  })
                }
              />

              <CheckListItem
                number={5}
                active={hasPaymentVerifications}
                ready={hasPaymentVerifications && !hasDocumentsToSign}
                title={<Trans>Sign documents</Trans>}
                buttonText={<Trans>Sign</Trans>}
                onPress={
                  hasDocumentsToSign &&
                  (() => {
                    if (languageCode) {
                      Linking.openURL(
                        `${Config.API_URL}/${languageCode}/common/employee/sign/documents/${user.employee.documentHash}`,
                      );
                    }
                  })
                }
              />

              <CheckListItem
                number={6}
                active={hasPaymentVerifications && !hasDocumentsToSign}
                ready={approvalRequested}
                pending={approvalRequested}
                title={<Trans>Request approval</Trans>}
                buttonText={<Trans>Request</Trans>}
              />

              {/*<CheckListItem
            number={4}
            active={paymentVerified}
            title={<Trans>Employment status</Trans>}
          />
          <CheckListItem
            number={5}
            active={false}
            title={<Trans>Skills</Trans>}
          /> */}
            </Box>
          </Box>
          {/*personalDetails && hasAdditionalInfo && hasIdentityVerifications && hasPaymentVerifications &&
          <>
            {!approvalRequested &&
                <Box>
                  <Button colorScheme={'light'} variant={'subtle'} onPress={async () => { await requestApproval(); }}>
                    <Box style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                      <Box style={{flexGrow: 1, flexShrink: 1}}>
                        <Text color={'primary.600'} fontSize={13}><Trans>Request approval</Trans></Text>
                      </Box>
                    </Box>
                  </Button>
                </Box>
            }

            {approvalRequested &&
                <Box key={uuidv4()} style={mainStyle.boxItemVertical}>
                  <Heading style={mainStyle.mediumTitle}><Trans>Approval requested</Trans></Heading>
                  <Box style={{width: '100%'}}>
                    <Text style={mainStyle.infoText}>
                      <Trans>We have received your request. You will receive a notification once your request has been approved.</Trans>
                    </Text>
                  </Box>
                </Box>
            }

          </>
      */}
        </>
      )}

      {user && user.employee && user.employee.reasonDenied && (
        <Box key={uuidv4()} style={mainStyle.boxItemVertical}>
          <Box style={{width: '100%'}}>
            <Heading style={mainStyle.mediumTitle}>
              <Trans>This employee has been denied</Trans>
            </Heading>
            {/*
                            <Text style={mainStyle.infoText}>
                                <Trans>Reason</Trans>: {user.employee.reasonDenied}
                            </Text>
                        */}
          </Box>
        </Box>
      )}
    </AppContainer>
  );
};

export default CompleteProfileScreen;
