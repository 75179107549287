import React, {useEffect, useState, useRef, useCallback} from 'react';
import {StyleSheet} from 'react-native';
import {
  Box,
  Heading,
  Button,
  Text,
  FormControl,
  Input,
  CheckIcon,
  Checkbox,
  VStack,
  Stack,
  Skeleton,
} from 'native-base';
import {Select} from '../../../Libs/CustomInputs';
import AppContainer from '../../Components/AppContainer';
import Trans from '../../Components/Trans';
import main from '../../../Assets/Styles/main.json';
import GeneralAction from '../../../Actions/GeneralAction';
import APIAction from '../../../Actions/APIAction';
import {v4 as uuidv4} from 'uuid';
import {NumberInput} from '../../../Libs/CustomInputs';
import FormAction from '../../../Actions/FormAction';
import TranslationAction from '../../../Actions/TranslationAction';
import UserAction from '../../../Actions/UserAction';

const mainStyle = StyleSheet.create(main);

const AdditionalInfoScreen = ({navigation}) => {
  // Init
  const firstLoad = useRef(true),
    [formKey, setFormKey] = useState(uuidv4()),
    [formErrors, setFormErrors] = useState([]),
    completedSteps = useRef([]),
    formData = useRef({
      user: {
        id: null,
      },
      employee: {
        id: null,
        driversLicense: '',
        car: false,
        nbrSeatsInCar: 0,
        bodyLength: 0,
        sizeShoe: 0,
        sizeTop: null,
        sizeBottom: null,
      },
    }),
    [translations, setTranslations] = useState({
      Select: null,
    }),
    translated = useRef(false),
    [sizeOptions, setSizeOptions] = useState([]),
    [dataFetched, setDataFetched] = useState(false),
    [waiting, setWaiting] = useState(false);
  /**
   * Set translations once
   */
  useEffect(() => {
    const translate = async () => {
      let newTranslations = await TranslationAction.translateInLine(
        Object.keys(translations),
      );
      setTranslations(newTranslations);
    };
    if (!translated.current) {
      translated.current = true;
      translate();
    }
  }, [translations]);

  /**
   * Fetch data to fill form
   */
  const fetchData = useCallback(() => {
    const init = async () => {
      //set sizes
      let sizes = await GeneralAction.getSizes();
      let newSizeOptions = [];
      for (let size of sizes) {
        newSizeOptions.push(<Select.Item value={size} label={size} />);
      }
      setSizeOptions(newSizeOptions);

      //get current user
      let currentUser = await APIAction.request({
        method: 'GET',
        url: `/api/current/user`,
      });
      await UserAction.setUser(currentUser);

      //set completed steps
      completedSteps.current = currentUser.employee.completedSteps || [];

      //set values
      formData.current.user.id = currentUser.id;
      formData.current.employee.id = currentUser.employee.id;
      formData.current.employee.driversLicense =
        currentUser.employee.driversLicense || '';
      formData.current.employee.car = currentUser.employee.car;
      formData.current.employee.nbrSeatsInCar =
        currentUser.employee.nbrSeatsInCar || 0;
      formData.current.employee.bodyLength =
        currentUser.employee.bodyLength || 0;
      formData.current.employee.sizeShoe = currentUser.employee.sizeShoe || 0;
      formData.current.employee.sizeTop = currentUser.employee.sizeTop;
      formData.current.employee.sizeBottom = currentUser.employee.sizeBottom;

      //reset key after load
      setFormKey(uuidv4());
      setDataFetched(true);
    };
    init();
  }, []);

  /**
   * Trigger on first load
   */
  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      fetchData();
    }
  }, [fetchData]);

  /**
   * Easily validate fields
   *
   * @param {string} field Name of field with underscore between segments
   * @param {string} type Name of validator to use as available in FormAction
   * @param {array} errors Array that fields with errors will be appended to
   */
  const validateField = (field, type, errors) => {
    let splitField = field.split('_');
    let result = FormAction[type](
      formData.current[splitField[0]][splitField[1]],
    );
    if (!result) errors.push(field);
  };

  /**
   * Submit form
   */
  const submit = async () => {
    //name of step
    let nameStep = 'additionalInfo';

    //clear errors
    setFormErrors([]);

    //new error array
    let errors = [];

    //validate
    validateField('employee_sizeShoe', 'validateNumber', errors);
    validateField('employee_bodyLength', 'validateNumber', errors);
    validateField('employee_nbrSeatsInCar', 'validateNumber', errors);

    //set errors
    setFormErrors(errors);

    //if no errors, continue
    if (errors.length === 0) {
      //check commpleted steps
      if (!completedSteps.current.includes(nameStep)) {
        completedSteps.current.push(nameStep);
      }

      //create body
      let reqBody = {
        completedSteps: completedSteps.current,
        car: formData.current.employee.car,
        nbrSeatsInCar: formData.current.employee.nbrSeatsInCar,
        bodyLength: formData.current.employee.bodyLength,
        sizeShoe: formData.current.employee.sizeShoe,
      };
      if (formData.current.employee.driversLicense)
        reqBody['driversLicense'] = formData.current.employee.driversLicense;
      if (formData.current.employee.sizeTop)
        reqBody['sizeTop'] = formData.current.employee.sizeTop;
      if (formData.current.employee.sizeBottom)
        reqBody['sizeBottom'] = formData.current.employee.sizeBottom;

      //send employee changes
      await APIAction.request({
        method: 'PATCH',
        url: `/api/employees/${formData.current.employee.id}`,
        body: reqBody,
      });

      //send message
      //GeneralAction.toast('success', <Trans>Information saved</Trans>);
      setTimeout(() => {
        setWaiting(false);
        navigation.replace('CompleteProfile', {id: formData.current.user.id});
      }, 2000);
    } else {
      setWaiting(false);
    }
  };

  return (
    <AppContainer noTabBar>
      {dataFetched ? (
        <VStack space={2}>
          <Box style={mainStyle.pageTitleWrapper}>
            <Box style={{flexGrow: 1}}>
              <Heading style={mainStyle.pageTitle}>
                <Trans>Additional info</Trans>
              </Heading>
            </Box>
          </Box>

          {/* Input fields */}
          <Box>
            <FormControl
              isInvalid={formErrors.includes('employee_driversLicense')}>
              <FormControl.Label>
                <Text>
                  <Trans>Drivers license</Trans>
                </Text>
              </FormControl.Label>
              <Input
                key={`${formKey}_employee_driversLicense`}
                defaultValue={formData.current.employee.driversLicense}
                onChangeText={val => {
                  formData.current.employee.driversLicense = val;
                }}
              />
              <FormControl.ErrorMessage _text={{padding: 0}}>
                <Text>
                  <Trans>This field is not valid</Trans>
                </Text>
              </FormControl.ErrorMessage>
            </FormControl>
          </Box>
          <Stack
            space={2}
            direction={{
              base: 'column',
              md: 'row',
            }}
            alignItems={{
              base: 'stretch',
              md: 'center',
            }}>
            <Box flex={1}>
              <Checkbox
                key={`${formKey}_employee_car`}
                isInvalid={formErrors.includes('employee_car')}
                defaultIsChecked={formData.current.employee.car}
                onChange={val => {
                  formData.current.employee.car = val;
                }}>
                <Text>
                  <Trans>Do you have a car?</Trans>
                </Text>
              </Checkbox>
            </Box>
            <Box flex={1}>
              <FormControl
                isInvalid={formErrors.includes('employee_nbrSeatsInCar')}>
                <FormControl.Label>
                  <Text>
                    <Trans>Seats in car (including driver seat)</Trans>
                  </Text>
                </FormControl.Label>
                <NumberInput
                  key={`${formKey}_employee_nbrSeatsInCar`}
                  min={0}
                  value={formData.current.employee.nbrSeatsInCar}
                  onChange={val => {
                    formData.current.employee.nbrSeatsInCar = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is not valid</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
          </Stack>
          <Stack
            space={2}
            direction={{
              base: 'column',
              md: 'row',
            }}>
            <Box flex={1}>
              <FormControl
                isInvalid={formErrors.includes('employee_bodyLength')}>
                <FormControl.Label>
                  <Text>
                    <Trans>Body length</Trans>
                  </Text>
                </FormControl.Label>
                <NumberInput
                  key={`${formKey}_employee_bodyLength`}
                  min={0}
                  value={formData.current.employee.bodyLength}
                  onChange={val => {
                    formData.current.employee.bodyLength = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is not valid</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
            <Box flex={1}>
              <FormControl isInvalid={formErrors.includes('employee_sizeShoe')}>
                <FormControl.Label>
                  <Text>
                    <Trans>Size shoe</Trans>
                  </Text>
                </FormControl.Label>
                <NumberInput
                  key={`${formKey}_employee_sizeShoe`}
                  min={0}
                  value={formData.current.employee.sizeShoe}
                  onChange={val => {
                    formData.current.employee.sizeShoe = val;
                  }}
                />
                <FormControl.ErrorMessage _text={{padding: 0}}>
                  <Text>
                    <Trans>This field is not valid</Trans>
                  </Text>
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
          </Stack>
          <Stack
            space={2}
            direction={{
              base: 'column',
              md: 'row',
            }}>
            <Box flex={1}>
              <Box>
                <FormControl.Label>
                  <Text>
                    <Trans>Size top</Trans>
                  </Text>
                </FormControl.Label>
                <Select
                  isInvalid={formErrors.includes('employee_sizeTop')}
                  key={`${formKey}_employee_sizeTop`}
                  placeholder={translations['Select']}
                  _selectedItem={{endIcon: <CheckIcon size={5} />}}
                  defaultValue={formData.current.employee.sizeTop}
                  onValueChange={val => {
                    formData.current.employee.sizeTop = val;
                  }}>
                  {sizeOptions}
                </Select>
              </Box>
            </Box>
            <Box flex={1}>
              <Box>
                <FormControl.Label>
                  <Text>
                    <Trans>Size bottom</Trans>
                  </Text>
                </FormControl.Label>
                <Select
                  isInvalid={formErrors.includes('employee_sizeBottom')}
                  key={`${formKey}_employee_sizeBottom`}
                  placeholder={translations['Select']}
                  _selectedItem={{endIcon: <CheckIcon size={5} />}}
                  defaultValue={formData.current.employee.sizeBottom}
                  onValueChange={val => {
                    formData.current.employee.sizeBottom = val;
                  }}>
                  {sizeOptions}
                </Select>
              </Box>
            </Box>
          </Stack>

          {/* Submit */}
          <Box>
            <Button
              isDisabled={waiting}
              isLoading={waiting}
              colorScheme={'success'}
              onPress={() => {
                setWaiting(true);
                submit();
              }}>
              <Text color={'#fff'}>
                <Trans>Done</Trans>
              </Text>
            </Button>
          </Box>
        </VStack>
      ) : (
        <VStack space={2}>
          <Skeleton h={'20'} />
          <Skeleton h={'20'} />
          <Skeleton h={'20'} />
        </VStack>
      )}
    </AppContainer>
  );
};

export default AdditionalInfoScreen;
